import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  readonly msgsource = new BehaviorSubject<string>('0');
  telecast = this.msgsource.asObservable();
  constructor() { }
  editId(newmsg): void {
    setTimeout(() =>  this.msgsource.next(newmsg), 0);
  }
}
