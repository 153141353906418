import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from './customer';

@Injectable()
export class CustomerService {
    constructor(readonly http: HttpClient) { }

    getCustomersLarge(): Promise<any> {
        return this.http.get<any>('assets/customers-large.json')
            .toPromise()
            .then(res => res.data)
            .then(data => data);
    }
}
