<div class="h100 wd">
    <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-6 pr-5 center bgColor h100">
            <img width="80%" class="imgClass center" src="../../assets/images/login_image.svg" alt="">
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 inherit">
            <div class="row mt23">
                <div class="col-md-12 col-sm-12 col-xs-12 my-auto">
                    <div>
                        <img width="63%" style="margin-left: 18%;" src="../../assets/images/logo.png" alt="">
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 tCenter">
                    <div class="col-md-11 col-sm-11 col-xs-11">
                        <h3 class="loginData ml-5">Two Factor Authentication</h3>
                    </div>
                </div>
                <div class="col-md-12  col-sm-12 col-xs-12">
                    <div class="row ">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <button type="button" class="button1">Use Google Authentication</button>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <button type="button" class="button2">Use Phone No.</button>
                        </div>
                    </div>
                </div>
                <form class=" ml31 mt-2">
                    <div class="col-md-12 col-sm-12 col-xs-12  code">
                        <div class="input-group form-group">
                            <input type="tel">
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12  mt-3">
                        <label for="">Verification Code</label>
                        <div class="input-group form-group">
                            <input type="text">
                        </div>
                        <br>
                        <div class="form-group mt-2">
                            <button type="submit" value="verify" class="submitButton btn btn-md">Verify</button>
                        </div>
                    </div>
                </form>
                <div class="col-md-12 col-sm-12 col-xs-12  mt-2" style="text-align: end;margin-left: -55px;">
                    <span class="skip mr-1">Skip</span> <img
                        src="..//..//assets/images/iconfinder_arrow_right2_1814086.svg" style="cursor: pointer;">
                </div>
            </div>
        </div>
    </div>
</div>