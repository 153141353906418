import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppService {
  updateHeader = new BehaviorSubject({ name: '', count: '' });
  headerSource$ = this.updateHeader.asObservable();
  sideBarSilde = new Subject<any>();
  sideBarSilde$ = this.sideBarSilde.asObservable();
  setPlanView = new Subject<any>();
  setPlanView$ = this.setPlanView.asObservable();
  contractorName: any;
  toggleSideBarView = new BehaviorSubject<boolean>(true);

  updatesideBarSlide(state: boolean): void {
    this.sideBarSilde.next(state);
  }

  toggleSideBar(value) {
    this.toggleSideBarView.next(value)
  }
}
