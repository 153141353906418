<div class="col-md-12 col-sm-12 col-xs-12" style="margin: 30px 0 49px -15px;">
    <div class="row">
        <div class="col-md-8 col-sm-9 col-xs-9">
            <div>
                <h3 style="color: #015A06; font-size: 35px;" *ngIf="this.userType=='Employee'">
                    <span style="position: relative; top: -px;cursor: pointer;font-weight: 500;" class="mr-5 responsive-text"
                        *ngIf="this.message">
                        {{this.message}}
                    </span>
                </h3>
                <h3 *ngIf="this.userType!='Employee'" style="color: #015A06; font-size: 35px;" (click)="editEmployee()">
                    <span style="position: relative; top: -4px;cursor: pointer;font-weight: 500;" class="mr-5 responsive-text"
                        *ngIf="this.message">
                        {{this.message}}
                    </span>
                </h3>
            </div>
            <div style="color: #3D0859; font-size: 15px;">
                <span style="position: relative; top: -4px;cursor: pointer;"
                    *ngIf="!router.url.includes('/allClient/employerSetup')">
                    <img src="../../assets/home.svg" alt="home" (click)="dashboard()">
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/userManagement/employee-role')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Policy&nbsp;&nbsp;
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/confirm-account')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Payroll Setup&nbsp;&nbsp;>&nbsp;&nbsp;Confirm Bank Account
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/twice-month-pay-schedule')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;<b style="cursor: pointer;" (click)="paySchedule()">Pay
                        Schedule</b>&nbsp;&nbsp;>&nbsp;&nbsp;Create Pay Schedule
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/payschedulemonth')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;<b style="cursor: pointer;" (click)="paySchedule()">Pay
                        Schedule</b>&nbsp;&nbsp;>&nbsp;&nbsp;Create Pay Schedule
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/confirm-bank-account')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Payroll Setup&nbsp;&nbsp;>&nbsp;&nbsp;Confirm Bank Account
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/employer-bank-account')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Payroll Setup&nbsp;&nbsp;>&nbsp;&nbsp;Employer Bank Account
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/create-garnishments')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;<b style="cursor: pointer;font-weight: 500;"
                        (click)="contributionInfo()">Contribution Info</b>&nbsp;&nbsp;>&nbsp;&nbsp;Create Contribution
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/create-deduction-contribution')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;<b style="cursor: pointer;font-weight: 500;"
                        (click)="contributionInfo()">Contribution Info</b>&nbsp;&nbsp;>&nbsp;&nbsp;Create Contribution
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/create-time-off-policies')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;<b style="cursor: pointer;font-weight: 500;" (click)="timeOffPolicy()">Time
                        off Policies</b>&nbsp;&nbsp;>&nbsp;&nbsp;Create Time off Policies
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/create-pay-schedule')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;<b style="cursor: pointer;font-weight: 500;" (click)="paySchedule()">Pay
                        Schedule</b>&nbsp;&nbsp;>&nbsp;&nbsp;Create Pay Schedule
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/direct-deposit')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Payroll Setup&nbsp;&nbsp;>&nbsp;&nbsp;Direct Deposit
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/deduction-contribution')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Payroll Setup&nbsp;&nbsp;>&nbsp;&nbsp;Contribution List
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/time-off-policies')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Payroll Setup&nbsp;&nbsp;>&nbsp;&nbsp;Time Off Policies
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/payroll/pay-schedule')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Payroll Setup&nbsp;&nbsp;>&nbsp;&nbsp;Pay Schedules
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/twoFactorAuthentication')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Two Factor Authentication
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/admin-report/admin-billing')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Admin Reports&nbsp;&nbsp;>&nbsp;&nbsp;Billing
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/admin-report/admin-contractor-detail')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Admin Reports&nbsp;&nbsp;>&nbsp;&nbsp;Contractor Detail
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/admin-report/employee-admin-detail')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Admin Reports&nbsp;&nbsp;>&nbsp;&nbsp;Employee Detail
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/reportEmployee/employee-payroll-detail')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employee Reports&nbsp;&nbsp;>&nbsp;&nbsp;Payroll Details
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/reportEmployee/employee-deduction-contribution')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employee Reports&nbsp;&nbsp;>&nbsp;&nbsp;Deductions and Contributions
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/reportEmployee/employee-pay-checklist')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employee Reports&nbsp;&nbsp;>&nbsp;&nbsp;Pay Check List
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/reportEmployee/payroll-summary')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employee Reports&nbsp;&nbsp;>&nbsp;&nbsp;Payroll Summary
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/payContractor/contractor-pay-summary')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Contractor Reports&nbsp;&nbsp;>&nbsp;&nbsp;Contractor Pay Summary
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/payContractor/contractor-payment-list')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Contractor Reports&nbsp;&nbsp;>&nbsp;&nbsp;Contractor Payment List
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/admin-report/team-member-directory')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Admin Reports&nbsp;&nbsp;>&nbsp;&nbsp;Team Member Directory
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/retirement-plan')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Retirement Plan
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/vacation-sick-leave')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Vacation and Sick Leave
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/worker-compensation-class')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Workers Compensation Class
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/total-cost-report')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Total Cost Report
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/report-tax-liability')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Tax Liability
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/report-tax-payment')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Tax Payment
                </span>

                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/detail-view-checklist-summary')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employee Reports&nbsp;&nbsp;>&nbsp;&nbsp;Pay Check List
                </span>

                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/tax-wage-summary')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Tax and Wage Summary
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/report/employeerReport/tax-wage-detail')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer Reports&nbsp;&nbsp;>&nbsp;&nbsp;Tax and Wage Details
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/filling/prior-tax-filings')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Prior Tax Fillings&nbsp;&nbsp;>&nbsp;&nbsp;Archived Federal Forms
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/filling/archived-state-forms')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Prior Tax Fillings&nbsp;&nbsp;>&nbsp;&nbsp;Archived State Forms
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/filling/archived-yearly-forms')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Prior Tax Fillings&nbsp;&nbsp;>&nbsp;&nbsp;Archived Yearly Forms
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/processTaxes/process-tax-forms')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Process Pay Forms&nbsp;&nbsp;>&nbsp;&nbsp;Quarterly Forms
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/processTaxes/yearly-forms')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Process Pay Forms&nbsp;&nbsp;>&nbsp;&nbsp;Yearly Forms
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/liability/pay-taxes')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Pay Taxes&nbsp;&nbsp;>&nbsp;&nbsp;Process Tax Liability Payments
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/liability/create-tax-payment')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Pay Taxes&nbsp;&nbsp;>&nbsp;&nbsp;Process Tax Liability
                    Payments&nbsp;&nbsp;>&nbsp;&nbsp;Approve Payment
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/taxes/liability/print-tax-payment')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Pay Taxes&nbsp;&nbsp;>&nbsp;&nbsp;Process Tax Liability
                    Payments&nbsp;&nbsp;>&nbsp;&nbsp;Approve Payment&nbsp;&nbsp;>&nbsp;&nbsp;Print Payment
                </span>


                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/authenticatTwoVerified')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Two Factor Authentication&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/process/main-payroll/second-process-payroll')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Create Second Paychecks&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/process/main-payroll/payroll')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Create Paychecks&nbsp;&nbsp;&nbsp;&nbsp;
                </span>

                <span style="position: relative; top: -2px;font-weight: 500;" *ngIf="router.url.includes('/allUser')"
                    class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Users
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;" *ngIf="router.url.includes('/userRoles')"
                    class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;User Roles
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/allClient/teamMembers/allteamMember')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Team Member Setup&nbsp;&nbsp;>&nbsp;&nbsp;All Team Members
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/employerSetup/allClient/employer-setup')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employer&nbsp;<span *ngIf="!this.roleValueLogin[11].isRead">Setup</span>
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;" *ngIf="router.url.includes('/dashboard')"
                    class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Dashboard&nbsp;&nbsp;&nbsp;&nbsp;
                </span>

                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/prior-taxes-paid')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Prior Payroll and Taxes&nbsp;&nbsp;>&nbsp;&nbsp;Prior Taxes Paid
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/process/main-payroll/edit-process-payroll')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Process Payroll&nbsp;&nbsp;>&nbsp;&nbsp;Edit Process Payroll
                </span>
                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/process/main-payroll/view-contractor-paystub')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Process Payroll&nbsp;&nbsp;>&nbsp;&nbsp;View Contractor Process Payroll
                </span>

                <span style="position: relative; top: -2px;font-weight: 500;"
                    *ngIf="router.url.includes('/process/main-payroll/second-process-view')" class="mr-5">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Process Payroll&nbsp;&nbsp;>&nbsp;&nbsp;Second Process Payroll
                </span>

                <span style="font-weight: 500;"
                    *ngIf="this.employeeNameFixed&&router.url.includes('/allClient/teamMembers/employee/employeeBasics')"
                    class="mr-5 classSpan">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employees&nbsp;&nbsp;>&nbsp;&nbsp;Basics&nbsp;&nbsp;>&nbsp;&nbsp;{{employeeNameFixed}}

                </span>
                <span style="font-weight: 500;"
                    *ngIf="this.employeeNameFixed&&router.url.includes('/allClient/teamMembers/employee/viewEmployeeDetail')"
                    class="mr-5 classSpan">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employees&nbsp;&nbsp;>&nbsp;&nbsp;Basics&nbsp;&nbsp;>&nbsp;&nbsp;{{employeeNameFixed}}

                </span>
                <span style="font-weight: 500;"
                    *ngIf="!this.employeeNameFixed&&router.url.includes('/allClient/teamMembers/employee/employeeBasics')"
                    class="mr-5 classSpan">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Employees&nbsp;&nbsp;>&nbsp;&nbsp;Basics&nbsp;&nbsp;>&nbsp;&nbsp;Employee
                    Name

                </span>
                <span style="font-weight: 500;"
                    *ngIf="this.employeeNameFixed&&router.url.includes('/allClient/prior/priorPayroll')"
                    class="mr-5 classSpan">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Prior Payroll and Taxes&nbsp;&nbsp;>&nbsp;&nbsp;Prior
                    Payroll&nbsp;&nbsp;>&nbsp;&nbsp;{{employeeNameFixed}}

                </span>
                <span style="font-weight: 500;"
                    *ngIf="!this.employeeNameFixed&&router.url.includes('/allClient/prior/priorPayroll')"
                    class="mr-5 classSpan">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Prior Payroll and Taxes&nbsp;&nbsp;>&nbsp;&nbsp;Prior
                    Payroll&nbsp;&nbsp;>&nbsp;&nbsp;Employee Name

                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                    *ngIf="this.firstNameHead&&router.url.includes('/allClient/teamMembers/contractor/contractorBasic')">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Contractors&nbsp;&nbsp;>&nbsp;&nbsp;Basics&nbsp;&nbsp;>&nbsp;&nbsp;{{this.firstNameHead}}
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                    *ngIf="!this.firstNameHead&&router.url.includes('/allClient/teamMembers/contractor/contractorBasic')">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Contractors&nbsp;&nbsp;>&nbsp;&nbsp;Basics&nbsp;&nbsp;>&nbsp;&nbsp;Contractor
                    Name
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                    *ngIf="router.url.includes('/subscription-management')">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Subscription Management
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                    *ngIf="router.url.includes('/create-new-subscription')">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Create Subscription
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                    *ngIf="router.url.includes('/buy-subscription')">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Buy Subscription
                </span>
                <span style="font-weight: 500;" class="classSpan"
                    *ngIf="router.url.includes('/subscription/')">
                    &nbsp;&nbsp;>&nbsp;&nbsp;Subscription
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                    *ngIf="router.url.includes('/create-plan')">
                    >&nbsp;&nbsp;Create Plan
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                *ngIf="router.url.includes('/all-plans')">
                    >&nbsp;&nbsp;All Plans
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                *ngIf="router.url.includes('/new-subscription')">
                    >&nbsp;&nbsp;Assign Plans
                </span>
                <span style="font-weight: 500;" class="mr-5 classSpan"
                *ngIf="router.url.includes('/all-tenents')">
                    >&nbsp;&nbsp;All Clients Plans
                </span>
            </div>
        </div>
        <div class="col-md-4 col-sm-3 col-xs-3">
            <div class="col-md-12 cl-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12"
                        style="text-align:right;padding-left: 0px;font-size: 14px;">
                        <div class="row" style="display: flex;justify-content: flex-end;">

                            <div class="dropdown" style="max-width: 187px;">
                                <div class="login-user-img">
                                    <img src="../../assets/images/user.png" alt="" width="55px">
                                    <span class="user-name color-white dropdown-toggle" data-toggle="dropdown"
                                        style="color: gray; margin-left: 10px; display: inline-table !important;text-align: left;">
                                        <b style="color:#000000!important;font-weight: 500;">{{firstName}}</b>
                                        <br>
                                        {{userId}}
                                    </span>
                                </div>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" (click)="logout()">Logout</a>
                                </div>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="logout()">Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>