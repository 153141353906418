import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(readonly http: HttpClient) { }

  loginDetails(loginUser): Observable<any> {
    return this.http.post(URLS.loginAPI, loginUser);
  }

  sendVerifyEmail(payload): Observable<any> {
    return this.http.put(URLS.verifyEmail, payload);
  }

  resetPassword(payload, id): Observable<any> {
    const resetPwd = URLS.resetPwd;
    const password = resetPwd.replace(':token', id);
    return this.http.put(password, payload);
  }
}
