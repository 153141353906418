import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from './customer';
import { URLS } from '../../../app/app.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    constructor(
        readonly http: HttpClient
    ) { }

    getCustomersLarge(): Promise<Customer[]> {
        return this.http.get<any>('assets/customers-large.json')
        .toPromise()
        .then(res => res.data as Customer[]).then(data => data);
    }

    getAllList(reqBody): Observable<any> {
        return this.http.post(URLS.getEmploySetup, reqBody);
    }

    getSubscriptionPlan(reqBody): Observable<any> {
        return this.http.get(URLS.getSubscriptionPlan, reqBody)
        .pipe(map((res: any) => res.data));
    }

    onUploadImage(value): Observable<any> {
        return this.http.post(URLS.uploadTable, value);
    }

    onUploadAllTeamFile(value, id?): Observable<any> {
        const url = `${URLS.teamMemberBulkUpload}?employerId=${id}`;
        return this.http.post(url, value)
    }

    deleteMulClient(reqBody): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: reqBody
        };
        return this.http.delete(URLS.deleteBulkClient, options);
    }

    getStates(): Observable<any> {
        return this.http.get(URLS.getStates);
    }

    getDepositor(): Observable<any> {
        return this.http.get(URLS.getDepositor);
    }

    getDataList(): Observable<any> {
        return this.http.get(URLS.getEmployeeList);
    }

    getEmployee(id): Observable<any> {
        let getEmployee = URLS.getEmployee;
        getEmployee = getEmployee.replace(':id', id);
        return this.http.get(getEmployee, id);
    }

    getProcessTaxLiability(id): Observable<any> {
        let getProcessTaxLiability = URLS.getProcessTaxLiability;
        getProcessTaxLiability = getProcessTaxLiability.replace(':id', id);
        return this.http.get(getProcessTaxLiability, id);
    }

    getGeneralTax(id): Observable<any> {
        let getGeneralTax = URLS.getGeneralTax;
        getGeneralTax = getGeneralTax.replace(':id', id);
        return this.http.get(getGeneralTax, id);
    }

    getEmpEmployment(id): Observable<any> {
        let getEmpEmployment = URLS.getEmpEmployment;
        getEmpEmployment = getEmpEmployment.replace(':id', id);
        return this.http.get(getEmpEmployment, id);
    }
    getEmployeeByEmployer(id): Observable<any> {
        let getEmployeeByEmployer = URLS.getEmployeeByEmployer;
        getEmployeeByEmployer = getEmployeeByEmployer.replace(':id', id);
        return this.http.get(getEmployeeByEmployer, id);
    }
    getTablePaycheck(id): Observable<any> {
        let getTablePaycheck = URLS.getTablePaycheck;
        getTablePaycheck = getTablePaycheck.replace(':id', id);
        return this.http.get(getTablePaycheck, id);
    }


    getPrintPayCheck(id): Observable<any> {
        let getPrintPayCheck = URLS.getPrintPayCheck;
        getPrintPayCheck = getPrintPayCheck.replace(':id', id);
        return this.http.get(getPrintPayCheck, id);
    }
    getApprovePayCheck(id): Observable<any> {
        let getApprovePayCheck = URLS.getApprovePayCheck;
        getApprovePayCheck = getApprovePayCheck.replace(':id', id);
        return this.http.get(getApprovePayCheck, id);
    }

    getContractorByEmployer(id): Observable<any> {
        let getContractorByEmployer = URLS.getContractorByEmployer;
        getContractorByEmployer = getContractorByEmployer.replace(':id', id);
        return this.http.get(getContractorByEmployer, id);
    }
    getTaxExemExemptions(id): Observable<any> {
        let getTaxExemExemptions = URLS.getTaxExemExemptions;
        getTaxExemExemptions = getTaxExemExemptions.replace(':id', id);
        return this.http.get(getTaxExemExemptions, id);
    }

    getContPay(id): Observable<any> {
        let getContPay = URLS.getContPay;
        getContPay = getContPay.replace(':id', id);
        return this.http.get(getContPay, id);
    }
    getEmpPay(id): Observable<any> {
        let getEmpPay = URLS.getEmpPay;
        getEmpPay = getEmpPay.replace(':id', id);
        return this.http.get(getEmpPay, id);
    }


    updateRole(id, data): Observable<any> {
        let updateClientRole = URLS.updateClientRole;
        updateClientRole = updateClientRole.replace(':id', id);
        updateClientRole = `${updateClientRole}?status=${data.status}`;
        return this.http.put(updateClientRole, data);
    }
    updatePaySched(id, data): Observable<any> {
        let updatePaySch = URLS.updatePaySch;
        updatePaySch = updatePaySch.replace(':id', id);
        updatePaySch = `${updatePaySch}?status=${data.status}`;
        return this.http.put(updatePaySch, data);
    }
    updateTimePolicyStatus(id, data): Observable<any> {
        let updateTimePolicy = URLS.employeeTimeOff;
        updateTimePolicy = updateTimePolicy.replace(':id', id);
        updateTimePolicy = `${updateTimePolicy}?status=${data.status}`;
        return this.http.put(updateTimePolicy, data);
    }
    updatededuction(id, data): Observable<any> {
        let updateDed = URLS.updatecontributionDeduction;
        updateDed = updateDed.replace(':id', id);
        updateDed = `${updateDed}?status=${data.status}`;
        return this.http.put(updateDed, data);
    }
    updateCheck(id, data): Observable<any> {
        let updateClientRole = URLS.updateCheck;
        updateClientRole = updateClientRole.replace(':id', id);
        updateClientRole = `${updateClientRole}?status=${data.status}`;
        return this.http.put(updateClientRole, data);
    }
    updateCheckPrint(id, data): Observable<any> {
        let updateClientRole = URLS.updateCheckPrint;
        updateClientRole = updateClientRole.replace(':id', id);
        updateClientRole = `${updateClientRole}?status=${data.status}`;
        return this.http.put(updateClientRole, data);
    }

    getQuarterInfo(id, data, date?): Observable<any> {
        let getQuarterInfo = URLS.getQuarterInfo;
        getQuarterInfo = getQuarterInfo.replace(':id', id);
        if(date) {
            getQuarterInfo = `${getQuarterInfo}?quarterName=${data}&localDate=${date}`;
        } else {
            getQuarterInfo = `${getQuarterInfo}?quarterName=${data}`;
        }
        return this.http.get(getQuarterInfo);
    }

    getTaxQuarterInfo(id, data): Observable<any> {
        let getTaxQuarterInfo = URLS.getTaxQuarterInfo;
        getTaxQuarterInfo = getTaxQuarterInfo.replace(':id', id);
        getTaxQuarterInfo = `${getTaxQuarterInfo}?quarterName=${data}`;
        return this.http.get(getTaxQuarterInfo);
    }


    getStateTax(id): Observable<any> {
        let getStateTax = URLS.getStateTax;
        getStateTax = getStateTax.replace(':id', id);
        return this.http.get(getStateTax, id);
    }

    getLocalTax(id): Observable<any> {
        let getLocalTax = URLS.getLocalTax;
        getLocalTax = getLocalTax.replace(':id', id);
        return this.http.get(getLocalTax, id);
    }

    getFederalTax(id): Observable<any> {
        let getFederalTax = URLS.getFederalTax;
        getFederalTax = getFederalTax.replace(':id', id);
        return this.http.get(getFederalTax, id);
    }

    getACHTax(id): Observable<any> {
        let getACHTax = URLS.getACHTax;
        getACHTax = getACHTax.replace(':id', id);
        return this.http.get(getACHTax, id);
    }

    getServiceOption(id): Observable<any> {
        let getServiceOption = URLS.getServiceOption;
        getServiceOption = getServiceOption.replace(':id', id);
        return this.http.get(getServiceOption, id);
    }

    saveEmployer(reqBody): Observable<any> {
        return this.http.post(URLS.saveEmployer, reqBody);
    }

    saveGeneralTax(id, reqBody): Observable<any> {
        let saveGeneralTax = URLS.saveGeneralTax;
        saveGeneralTax = saveGeneralTax.replace(':id', id);
        return this.http.post(saveGeneralTax, reqBody);
    }

    sacepaycheckDetails(id, reqBody): Observable<any> {
        let sacepaycheckDetails = URLS.sacepaycheckDetails;
        sacepaycheckDetails = sacepaycheckDetails.replace(':id', id);
        return this.http.post(sacepaycheckDetails, reqBody);
    }

    postApprovePayCheck(id, reqBody): Observable<any> {
        let postApprovePayCheck = URLS.postApprovePayCheck;
        postApprovePayCheck = postApprovePayCheck.replace(':id', id);
        return this.http.post(postApprovePayCheck, reqBody);
    }
    saveACHTax(id, reqBody): Observable<any> {
        let saveACHTax = URLS.saveACHTax;
        saveACHTax = saveACHTax.replace(':id', id);
        return this.http.post(saveACHTax, reqBody);
    }

    saveFederalTax(id, reqBody): Observable<any> {
        let saveFederalTax = URLS.saveFederalTax;
        saveFederalTax = saveFederalTax.replace(':id', id);
        return this.http.post(saveFederalTax, reqBody);
    }

    saveMemberQuarter(reqBody, id): Observable<any> {
        let saveMemberQuarter = URLS.saveMemberQuarter;
        saveMemberQuarter = saveMemberQuarter.replace(':id', id);
        return this.http.post(saveMemberQuarter, reqBody);
    }
    savePaycheck(reqBody, id): Observable<any> {
        let savePaycheck = URLS.savePaycheck;
        savePaycheck = savePaycheck.replace(':id', id);
        return this.http.post(savePaycheck, reqBody);
    }

    saveTaxPaycheck(reqBody, id): Observable<any> {
        let saveTaxPaycheck = URLS.saveTaxPaycheck;
        saveTaxPaycheck = saveTaxPaycheck.replace(':id', id);
        return this.http.post(saveTaxPaycheck, reqBody);
    }

    savePriorPaidTaxes(reqBody, id): Observable<any> {
        let savePriorPaidTaxes = URLS.savePriorPaidTaxes;
        savePriorPaidTaxes = savePriorPaidTaxes.replace(':id', id);
        return this.http.post(savePriorPaidTaxes, reqBody);
    }

    saveTaxesAndExempt(reqBody, id): Observable<any> {
        let saveTaxesAndExmept = URLS.saveTaxesAndExmept;
        saveTaxesAndExmept = saveTaxesAndExmept.replace(':id', id);
        return this.http.post(saveTaxesAndExmept, reqBody);
    }

    saveServiceOption(id, reqBody, file): Observable<any> {
        let saveServiceOption = URLS.saveServiceOption;
        saveServiceOption = saveServiceOption.replace(':id', id);
        saveServiceOption = `${saveServiceOption}?subscriptionPlanId=${reqBody.subscriptionPlanId}&state=${reqBody.stateId}`;
        return this.http.post(saveServiceOption, file);
    }

    saveStateTax(id, reqBody): Observable<any> {
        let stateTax = URLS.stateTax;
        stateTax = stateTax.replace(':id', id);
        return this.http.post(stateTax, reqBody);
    }

    saveLocalTax(id, reqBody): Observable<any> {
        let localTax = URLS.localTax;
        localTax = localTax.replace(':id', id);
        return this.http.post(localTax, reqBody);
    }

    saveContractor(reqBody, id): Observable<any> {
        let saveContractor = URLS.saveContractor;
        saveContractor = saveContractor.replace(':id', id);
        return this.http.post(saveContractor, reqBody);
    }

    saveBusiness(reqBody, id): Observable<any> {
        let saveBusiness = URLS.saveBusiness;
        saveBusiness = saveBusiness.replace(':id', id);
        return this.http.post(saveBusiness, reqBody);
    }

    saveBusinessContractor(reqBody, id): Observable<any> {
        let saveBusinessContractor = URLS.saveBusinessContractor;
        saveBusinessContractor = saveBusinessContractor.replace(':id', id);
        return this.http.post(saveBusinessContractor, reqBody);
    }

    savePayContractor(reqBody, id): Observable<any> {
        let savePayContractor = URLS.savePayContractor;
        savePayContractor = savePayContractor.replace(':id', id);
        return this.http.post(savePayContractor, reqBody);
    }

    saveW2Employee(reqBody, id): Observable<any> {
        let createEmployeeW2 = URLS.createEmployeeW2;
        createEmployeeW2 = createEmployeeW2.replace(':id', id);
        return this.http.post(createEmployeeW2, reqBody);
    }

    createEmployeeW2Save(reqBody, id): Observable<any> {
        let createEmployeeW2Save = URLS.createEmployeeW2Save;
        createEmployeeW2Save = createEmployeeW2Save.replace(':id', id);
        return this.http.post(createEmployeeW2Save, reqBody);
    }

    deleteClient(id): Observable<any> {
        let deleteClient = URLS.deleteClient;
        deleteClient = deleteClient.replace(':id', id);
        return this.http.delete(deleteClient, id);
    }

    tempDownload(value): Observable<any> {
        return this.http.post(URLS.templateDownload, value, { responseType: 'blob' });
    }

    deleteEmployeeDetails(ids, bulkUpload?: boolean): Observable<any> {
        let deleteEmployeeDetails = bulkUpload ? URLS.deleteAllEmployee : URLS.deleteEmployeeDetails;
        if(!bulkUpload) {
            deleteEmployeeDetails = deleteEmployeeDetails.replace(':id', ids);
            return this.http.delete(deleteEmployeeDetails, ids, );
        } else {
            return this.http.post(deleteEmployeeDetails, ids);
        }
    }

    getAllEmpList(payload): Observable<any> {
        return this.http.post(URLS.getAllEmploye, payload, { responseType: 'json' });
    }

    getTaxReport(request, id): Observable<any> {
        const url = URLS.employerTaxReport.replace(':id', id);
        return this.http.post(url, request);
    }
}
