import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SideShowHideService {
  readonly msgsource = new BehaviorSubject<string>('T');
  telecast = this.msgsource.asObservable();
  constructor() { }

  editMsg(newmsg): void {
    this.msgsource.next(newmsg);
  }
}
