import { Component, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { LoaderService } from '../service/loader.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements AfterViewChecked {
  color = 'primary';
  value = 100;
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(readonly loaderService: LoaderService, readonly cdRef: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
}
