import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { AppInterceptor } from './service/app.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { UserManagementComponent } from './user-management/user-management.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { UserRolesComponent } from './user-management/user-roles/user-roles.component';
import { CustomerService } from '../app/user-management/customerService';
import { MainContainerComponent } from './main-container/main-container.component';
// import { ClientComponent } from './client/client.component';
// import { ProcessPayrollComponent } from './process-payroll/process-payroll.component';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
// import { EmployeeComponent } from './client/employee/employee.component';
// import { ContractorComponent } from './client/contractor/contractor.component';
// import { AllTeamComponent } from './client/all-team/all-team.component';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastrModule } from 'ngx-toastr';
// import { EmployerSetupComponent } from './client/employer-setup/employer-setup.component';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
// import { PriorPayrollAndTaxesComponent } from './client/prior-payroll-and-taxes/prior-payroll-and-taxes.component';
// import { PriorTaxesPaidComponent } from './client/prior-payroll-and-taxes/prior-taxes-paid/prior-taxes-paid.component';
// import { TaxesComponent } from './taxes/taxes.component';
// import { ProcessTaxFormsComponent } from './taxes/process-tax-forms/process-tax-forms.component';
// import { YearlyFormsComponent } from './taxes/process-tax-forms/yearly-forms/yearly-forms.component';
// import { PriorTaxFilingsComponent } from './taxes/prior-tax-filings/prior-tax-filings.component';
// import { FormVaultComponent } from './taxes/form-vault/form-vault.component';
// import { ArchivedStateFormsComponent } from './taxes/prior-tax-filings/archived-state-forms/archived-state-forms.component';
// import { ArchivedYearlyFormsComponent } from './taxes/prior-tax-filings/archived-yearly-forms/archived-yearly-forms.component';
import { ResetComponent } from './user/reset/reset.component';
import { EmailVerifiedSuccessfullyComponent } from './email-verified-successfully/email-verified-successfully.component';
// import { AuthenticationTwoVerificationComponent } from './authentication-two-verification/authentication-two-verification.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DatePipe } from '@angular/common';
// import { MainProcessComponent } from './main-process/main-process.component';
import { ChartsModule } from 'ng2-charts';
// import { CreateTaxPaymentComponent } from './taxes/create-tax-payment/create-tax-payment.component';
// import { PrintTaxPaymentComponent } from './taxes/print-tax-payment/print-tax-payment.component';
// import { ReportTaxLiabilityComponent } from './report/report-tax-liability/report-tax-liability.component';
// import { ReportTaxPaymentComponent } from './report/report-tax-payment/report-tax-payment.component';
// import { TaxWageSummaryComponent } from './report/tax-wage-summary/tax-wage-summary.component';
// import { TotalCostReportComponent } from './report/total-cost-report/total-cost-report.component';
// import { WorkerCompensationClassComponent } from './report/worker-compensation-class/worker-compensation-class.component';
// import { VacationSickLeaveComponent } from './report/vacation-sick-leave/vacation-sick-leave.component';
// import { RetirementPlanComponent } from './report/retirement-plan/retirement-plan.component';
// import { AdminReportComponent } from './report/admin-report/admin-report.component';
// import { ContractorPaymentListComponent } from './report/contractor-payment-list/contractor-payment-list.component';
// import { ContractorPaySummaryComponent } from './report/contractor-pay-summary/contractor-pay-summary.component';
// import { PayrollSummaryComponent } from './report/payroll-summary/payroll-summary.component';
// import { EmployeePayChecklistComponent } from './report/employee-pay-checklist/employee-pay-checklist.component';
// import { EmployeeDeductionContributionComponent } from './report/employee-deduction-contribution/employee-deduction-contribution.component';
// import { EmployeePayrollDetailComponent } from './report/employee-payroll-detail/employee-payroll-detail.component';
// import { EmployeeAdminDetailComponent } from './report/employee-admin-detail/employee-admin-detail.component';
// import { AdminContractorDetailComponent } from './report/admin-contractor-detail/admin-contractor-detail.component';
// import { AdminBillingComponent } from './report/admin-billing/admin-billing.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './service/loader.service';
import { LoaderComponent } from './loader/loader.component';
// import { PayScheduleComponent } from './payroll/pay-schedule/pay-schedule.component';
// import { TimeOffPoliciesComponent } from './payroll/time-off-policies/time-off-policies.component';
// import { DeductionContributionComponent } from './payroll/deduction-contribution/deduction-contribution.component';
// import { DirectDepositComponent } from './payroll/direct-deposit/direct-deposit.component';
// import { CreatePayScheduleComponent } from './payroll/create-pay-schedule/create-pay-schedule.component';
// import { CreateTimePoliciesComponent } from './payroll/create-time-policies/create-time-policies.component';
// import { CreateDeductionContributionComponent } from './payroll/create-deduction-contribution/create-deduction-contribution.component';
// import { CreateGarnishmentsComponent } from './payroll/create-garnishments/create-garnishments.component';
// import { EmployerBankAccountComponent } from './payroll/employer-bank-account/employer-bank-account.component';
import { RandomServiceService } from './service/random-service.service';
// import { ConfirmBankAccountComponent } from './payroll/confirm-bank-account/confirm-bank-account.component';
// import { ConfirmAccountComponent } from './payroll/confirm-account/confirm-account.component';
import { SideShowHideService } from './service/side-show-hide.service';
// import { PayScheduleMonthComponent } from './payroll/pay-schedule-month/pay-schedule-month.component';
// import { TwiceMonthPayScheduleComponent } from './payroll/twice-month-pay-schedule/twice-month-pay-schedule.component';
// import { ViewEmployeeDetailComponent } from './client/view-employee-detail/view-employee-detail.component';
// import { ViewContractorDetailComponent } from './client/view-contractor-detail/view-contractor-detail.component';
// import { EditProcessPayrollComponent } from './edit-process-payroll/edit-process-payroll.component';
// import { SecondProcessPayrollComponent } from './second-process-payroll/second-process-payroll.component';
// import { SecondProcessViewComponent } from './second-process-view/second-process-view.component';
// import { WagesDetailComponent } from './wages-detail/wages-detail.component';
// import { ViewContractorPaystubComponent } from './view-contractor-paystub/view-contractor-paystub.component';
// import { DetailViewChecklistSummaryComponent } from './report/detail-view-checklist-summary/detail-view-checklist-summary.component';
import { NgxCalendarModule } from 'ss-ngx-calendar';
import { EmployeeNameService } from './service/employee-name.service';
import { ContractorNameService } from './service/contractor-name.service';
// import { EmployeeAdminRoleComponent } from './employee-admin-role/employee-admin-role.component';
import { TwoDigitDecimaNumberDirectiveDirective } from './service/two-digit-decima-number-directive.directive';
import { EmployeeService } from './service/employee.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
// import { SvgIconDirective } from './svg-icon.directive';
// import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
// import { AchCalanderComponent } from './ach-calander/ach-calander.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
// import { SearchNamePipe } from './search-name.pipe';
// import { LiabilityTaxesComponent } from './report/liability-taxes/liability-taxes.component';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './shared.module';
import { AutoLogOffService } from './service/auto-logoff.service';
import { TrackCapsDirective } from './login/caps-lock.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    SidebarComponent,
    HeaderComponent,
    MainContainerComponent,
    TwoFactorAuthenticationComponent,
    VerifyEmailComponent,
    ResetComponent,
    EmailVerifiedSuccessfullyComponent,
    LoaderComponent,
    TrackCapsDirective
    // UserManagementComponent,
    // UserRolesComponent,
    // ClientComponent,
    // ProcessPayrollComponent,
    // EmployeeComponent,
    // ContractorComponent,
    // AllTeamComponent,
    // EmployerSetupComponent,
    // PriorPayrollAndTaxesComponent,
    // PriorTaxesPaidComponent,
    // TaxesComponent,
    // ProcessTaxFormsComponent,
    // YearlyFormsComponent,
    // PriorTaxFilingsComponent,
    // FormVaultComponent,
    // ArchivedStateFormsComponent,
    // ArchivedYearlyFormsComponent,
    // AuthenticationTwoVerificationComponent,
    // DashboardComponent,
    // CreateTaxPaymentComponent,
    // PrintTaxPaymentComponent,
    // ReportTaxLiabilityComponent,
    // ReportTaxPaymentComponent,
    // TaxWageSummaryComponent,
    // TotalCostReportComponent,
    // WorkerCompensationClassComponent,
    // VacationSickLeaveComponent,
    // RetirementPlanComponent,
    // AdminReportComponent,
    // ContractorPaymentListComponent,
    // ContractorPaySummaryComponent,
    // PayrollSummaryComponent,
    // EmployeePayChecklistComponent,
    // EmployeeDeductionContributionComponent,
    // EmployeePayrollDetailComponent,
    // EmployeeAdminDetailComponent,
    // AdminContractorDetailComponent,
    // AdminBillingComponent,
    // PayScheduleComponent,
    // TimeOffPoliciesComponent,
    // DeductionContributionComponent,
    // DirectDepositComponent,
    // CreatePayScheduleComponent,
    // CreateTimePoliciesComponent,
    // CreateDeductionContributionComponent,
    // CreateGarnishmentsComponent,
    // EmployerBankAccountComponent,
    // ConfirmBankAccountComponent,
    // ConfirmAccountComponent,
    // PayScheduleMonthComponent,
    // TwiceMonthPayScheduleComponent,
    // ViewEmployeeDetailComponent,
    // ViewContractorDetailComponent,
    // EditProcessPayrollComponent,
    // SecondProcessPayrollComponent,
    // SecondProcessViewComponent,
    // WagesDetailComponent,
    // ViewContractorPaystubComponent,
    // DetailViewChecklistSummaryComponent,
    // EmployeeAdminRoleComponent,
    // SvgIconDirective,
    // DashboardNewComponent,
    // AchCalanderComponent,
    // LiabilityTaxesComponent
    // TwoDigitDecimaNumberDirectiveDirective,
    // SearchNamePipe,
  ],
  imports: [
    SharedModule,
    TooltipModule,
    RadioButtonModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    NgxCalendarModule,
    ChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    QRCodeModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    ButtonModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      /*  preventDuplicates: true, */
    }),
    TableModule,
    ToastModule,
    CheckboxModule,
    TabViewModule,
    ScrollingModule,
    RippleModule,
    DropdownModule,
    DialogModule,
    FormsModule,
    AppRoutingModule,
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
    }, EmployeeService,
      CustomerService, DatePipe, LoaderService, ContractorNameService, RandomServiceService, SideShowHideService, EmployeeNameService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
