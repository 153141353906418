import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  constructor(
    // tslint:disable-next-line: variable-name
    public _formBuilder: FormBuilder,
    readonly primengConfig: PrimeNGConfig,
    readonly loginService: LoginService,
    readonly router: Router,
    readonly route: ActivatedRoute,
    readonly toast: ToastrService
  ) { }

  paramsObject: any;
  emailVerifyView: boolean;
  changePwdObj: any = {};
  userData: any = {};
  showText: boolean;
  showTextCnf: boolean;
  oneUpperCase: string;
  reqEmail: string;

  oneDigit: string;
  lowerCase: string;
  whiteSpace: string;
  specialChar: string;

  ngOnInit(): void {
    this.emailVerifyView = false;
    this.getRefreshToken();

  }

  getRefreshToken(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.paramsObject = { ...params.keys, ...params };
      const token = this.paramsObject.params.token;
    });
  }

  resetPassword(): void {
    const obj = {
      confirmPassword: this.changePwdObj.confirmPwd,
      newPassword: this.changePwdObj.password
    };
    const id = this.paramsObject.params.token;
    this.loginService.resetPassword(obj, id).subscribe((data: any) => {
      if (data && data.status === 200) {
        this.emailVerifyView = false;
        this.toast.success(data.message);
        this.router.navigate(['/login']);
      }
      else {
        this.toast.warning(data.message);
      }
    }, (err) => {
      this.toast.warning(err.message);
    });
  }

  pswdChange(value: string): void {
    const oneUpperCase = /(?=.*?[A-Z])/;
    const oneDigit = /(?=.*?[0-9])/;
    const lowerCase = /(?=.*?[a-z])/;
    const specialChar = /(?=.*?[#?!@$%^&*-])/;
    const whiteSpace = new RegExp('\\s+');
    if (!oneDigit.test(value)) {
      this.oneDigit = 'Password contains at least one digit.';
    } else { this.oneDigit = null; }
    if (!oneUpperCase.test(value)) {
      this.oneUpperCase = 'Password contains at least one upper case alphabet.';
    } else { this.oneUpperCase = null; }
    if (!lowerCase.test(value)) {
      this.lowerCase = 'Password contains at least one lower case alphabet.';
    } else { this.lowerCase = null; }
    if (!specialChar.test(value)) {
      this.specialChar = 'Password contains at least one special character which includes !@#$%&*()-+=^.';
    } else { this.specialChar = null; }
    if (whiteSpace.test(value)) {
      this.whiteSpace = 'Password doesn’t contain any white space.';
    } else { this.whiteSpace = null; }
  }

  verifyEmail(): void { }
}
