<section class="sectionClass">
    <div>
        <div class="h100 wd">
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-6 pr-5 center bgColor h100">
                    <img width="80%" class="imgClass center" src="../../assets/images/login_image.svg" alt="">
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 inherit">
                    <div class="row mt23">
                        <div class="col-md-12 my-auto">
                            <div>
                                <img width="63%" style="margin-left: 18%;" src="../../assets/images/logo.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-4 col-xs-12 tCenter">
                            <div class="col-md-11 col-sm-11 col-xs-11 ">
                                <h3 class="loginData ml-5 pl-3">Two Factor Authentication</h3>
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="row ">
                                    <div class="col-md-6 col-sm-6 col-xs-6" (click)="useGoogleAuthenyication()">
                                        <button type="button"
                                            [style.background-color]="googleAuth ? '#3D0859' : 'white'" class="button1">
                                            <span [style.color]="googleAuth ? 'white' : 'black'">Use Google
                                                Authentication</span></button>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" (click)="usePhones()">
                                        <button type="button" class="button2"
                                            [style.background-color]="phoneUse ? '#3D0859' : 'white'">
                                            <span [style.color]="phoneUse ? 'white' : 'black'">Use Phone
                                                No.</span></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12 code" *ngIf="this.googleAuth">
                                <div class="col-md-7 col-offset-2 classQr">
                                    <img [src]="qrCodeImage" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <form class="" [formGroup]="verifyForm" *ngIf="this.googleAuth">
                                <label for="" class="" style="margin-left:56px;">Verification Code</label>
                                <div class="input-group form-group tCenter">
                                    <input type="text" formControlName="verifyOtpInput" style="margin-left:56px;">
                                </div>
                                <br>
                                <div class="form-group mt-2 tCenter">
                                    <button type="submit" value="verify" (click)="onSubmit();"
                                        class="submitButton btn btn-md">Verify</button>
                                </div>
                            </form>
                            <form class="" [formGroup]="phoneNumberForm" *ngIf="this.phoneUse">
                                <br>
                                <div class="input-group form-group tCenter">
                                    <input type="text" formControlName="verifyOtpInput" style="margin-left:56px;">
                                </div>
                                <br>
                                <label for="" class="" style="margin-left:56px;">Verification Code</label>
                                <div class="input-group form-group tCenter">
                                    <input type="text" formControlName="verifyOtpInput" style="margin-left:56px;">
                                </div>
                                <div class="form-group mt-2 tCenter">
                                    <button type="submit" value="verify" (click)="onSubmitPhone();"
                                        class="submitButton btn btn-md">Verify</button>
                                </div>
                            </form>
                            <div class="col-md-12 mt-2 " style="text-align: end;margin-left: -55px;">
                                <span class="skip mr-1" (click)="skip()">Skip</span> <img
                                    src="..//..//assets/images/iconfinder_arrow_right2_1814086.svg"
                                    style="cursor: pointer;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br> <br>
        </div>
    </div>
</section>