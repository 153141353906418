import { environment } from './../environments/environment';
let url = environment.host_api_url;
// if (environment.production) {
//   url = `${window.location.protocol}//${window.location.hostname}/`;
// }

export const URLS = {
  // Auth Module
  loginAPI: `${url}api/v1/login`,
  getEmployeeList: `${url}api/v1/employer/fetch/all`,
  verifyEmail: `${url}api/v1/reset`,
  resetPwd: `${url}api/v1/reset_password?token=:token`,
   // User Management Module
  userFilteredData: `${url}api/v1/user/details/?`,
  createNewUser: `${url}api/v1/user`,
  editUser: `${url}api/v1/user/:id`,
  changePassword: `${url}api/v1/change_password`,
  getUserId: `${url}api/v1/user`,
  searchUser: `${url}api/v1/user/details/?`,
  userPermission: `${url}api/v1/permission`,
  userRoles: `${url}api/v1/role/list`,
  createNewRole: `${url}api/v1/role`,
  updateRole: `${url}api/v1/role/:id`,
  deleteRoles: `${url}api/v1/role`,
  deleteUsers: `${url}api/v1/user`,
  statusUsers: `${url}api/v1/user/:id/status?status=:status`,
  // All Client Module
  getEmploySetup: `${url}api/v1/employer/fetch/list`,
  uploadTable: `${url}api/v1/employer/bulk/upload`,
  deleteBulkClient: `${url}api/v1/employer/bulk/upload`,
  deleteClient: `${url}api/v1/employer/:id`,
  addAddress: `${url}api/v1/address/:id`,
  saveEmployer: `${url}api/v1/employer`,
  getStates: `${url}api/v1/states`,
  saveGeneralTax: `${url}api/v1/employer/general/tax/:id`,
  saveFederalTax: `${url}api/v1/employer/federal/tax/:id`,
  saveACHTax: `${url}api/v1/employer/ach/:id`,
  stateTax: `${url}api/v1/employer/state/tax/:id`,
  localTax: `${url}api/v1/employer/local/tax/:id`,
  getEmployee: `${url}api/v1/employer/:id`,
  getGeneralTax: `${url}api/v1/employer/general/tax/:id`,
  getFederalTax: `${url}api/v1/employer/federal/tax/:id`,
  getStateTax: `${url}api/v1/employer/state/tax/:id`,
  getLocalTax: `${url}api/v1/employer/local/tax/:id`,
  getACHTax: `${url}api/v1/employer/ach/:id`,
  getServiceOption: `${url}api/v1/employer/service/option/:id`,
  saveServiceOption: `${url}api/v1/employer/service/option/:id`,
  getSubscriptionPlan: `${url}api/v1/subscription/plan`,
  getDepositor: `${url}api/v1/employer/filler/depositor`,
  updateClientRole: `${url}api/v1/employer/:id`,
  updatePaySch: `${url}api/v1/update/payschedule/:id`,
  employeeTimeOff: `${url}api/v1/update/employeeTimeOff/:id`,
  updatecontributionDeduction: `${url}api/v1/update/contributionDeduction/:id`,

  updateCheck: `${url}api/v1/employee/:id`,
  updateCheckPrint: `${url}api/v1/update/paycheck/:id`,
  templateDownload: `${url}api/v1/employer/template`,
  getAllEmploye: `${url}api/v1/employee/employee/details`,
  deleteEmployeeDetails: `${url}api/v1/employee/:id`,
  saveMemberQuarter: `${url}api/v1/payroll/quarter/:id`,
  getEmployeeByEmployer: `${url}api/v1/employee/list/:id`,
  getContractorByEmployer: `${url}api/v1/contractor/list/:id`,
  teamMemberBulkUpload: `${url}api/v1/team/bulk/upload`,
  updateTeamMemberStatus: `${url}api/v1/updateTeamMemberStatus/:id?enable=:enable`,
  saveContractor: `${url}api/v1/contractor/:id`,
  savePayContractor: `${url}api/v1/contractor/pay/:id`,
  createEmployeeW2: `${url}api/v1/w2employee/:id`,
  createEmployeeW2Save: `${url}api/v1/w2employee/employment/:id`,
  saveBusiness: `${url}api/v1/business/:id`,
  saveBusinessContractor: `${url}api/v1/contractor/:id`,
  savePriorPaidTaxes: `${url}api/v1/taxes/quarter/:id`,
  saveTaxesAndExmept: `${url}api/v1/w2employee/taxes/exemptions/:id`,
  getQuarterInfo: `${url}api/v1/payroll/quarter/:id`,
  twoFactorVerify: `${url}api/v1/verify/enable/google/auth`,
  getZipCode: `${url}api/v1/zipCode`,
  google2FaImage: `${url}api/v1/enable/disable/google2Fa`,
  w2employee: `${url}api/v1/w2employee/w2employee/pay/:id`,
  editContractor: `${url}api/v1/contractor/:id`,
  editEmployeeList: `${url}api/v1/employee/:id`,
  verifyAccout: `${url}api/v1/verify`,
  editQuarterDetail: `${url}api/v1/payroll/quarter/:id`,
  savePaycheck: `${url}api/v1/payroll/by/paycheck/:id`,
  getPrintPaycheck: `${url}api/v1/print/paycheck/:id`,
  getTaxQuarterInfo: `${url}api/v1/taxes/quarter/:id`,
  saveTaxPaycheck: `${url}api/v1/taxes/by/paycheck/:id`,
  getpaycheck: `${url}api/v1/create/paycheck/:id`,
  getDashboard: `${url}api/v1/dashboard/details/:id`,
  getDashboardPieChart: `${url}api/v1/dashboard/chart/:id`,
  getContPay: `${url}api/v1/contractor/pay/:id`,
  getEmpPay: `${url}api/v1/w2employee/w2employee/pay/:id`,
  getEmpEmployment: `${url}api/v1/w2employee/employment/:id`,
  getTaxExemExemptions: `${url}api/v1/w2employee/taxes/exemptions/:id`,
  getTablePaycheck: `${url}api/v1/create/paycheck/:id`,
  getBusinessList: `${url}api/v1/business/list/:id`,
  getBusinessDetail: `${url}api/v1/business/:id`,
  sacepaycheckDetails: `${url}api/v1/create/paycheck/:id`,
  getPrintPayCheck: `${url}api/v1/print/paycheck/:id`,
  getApprovePayCheck: `${url}api/v1/fetch/paycheck/:id`,
  postApprovePayCheck: `${url}api/v1/approve/paycheck/:id`,
  getProcessTaxLiability: `${url}api/v1/pay/taxes/quarterly/form/:id`,
  approveProcessTaxLiability: `${url}api/v1/pay/taxes/approve/:id`,
  getTaxliability: `${url}api/v1/employer/tax-liability/report/:id`,
  getTaxPayment: `${url}api/v1/employer/tax-payment/report/:id`,
  getWorkerCompensation: `${url}api/v1/employer/worker-compensation/report/:id`,
  getSickVacation: `${url}api/v1/employer/sick-vacation/report/:id`,
  getRetirementPlan: `${url}api/v1/employer/retirement-plan/report/:id`,
  getTotalCost: `${url}api/v1/employer/total-cost/report/:id`,
  getWagesSummary: `${url}api/v1/employer/tax-wages/report/:id`,
  getContractorPaySummary: `${url}api/v1/contractor/pay-summary/report/:id`,
  getContractorPayMentSummary: `${url}api/v1/contractor/payments-list/report/:id`,
  getEmployeePayrollSummary: `${url}api/v1/employee/payroll-summary/report/:id`,
  getPayrollDetailSummary: `${url}api/v1/employee/payrollDetail/report/:id`,

  getPayCheckListSummary: `${url}api/v1/employee/paycheck-list/report/:id`,
  getDeductionSummary: `${url}api/v1/employee/deductions-contributions/report/:id`,
  getTeamMemberDirectory: `${url}api/v1/admin/team/member-directory/report/:id`,
  getEmployeeDeatils: `${url}api/v1/admin/employee-details/report/:id`,
  getContractorDetail: `${url}api/v1/admin/contractor-details/report/:id`,
  getBillingDetail: `${url}api/v1/admin/billing/report`,
  getTurnOffPolicy: `${url}api/v1/employer/turn-off/policy/all/:id`,
  postTurnOffPolicy: `${url}api/v1/employer/turn-off/policy/:id`,
  getCategoryList: `${url}api/v1/category/list`,
  getfrequency: `${url}api/v1/accrual-frequency/list`,
  getTurnOffPolicyById: `${url}api/v1/employer/turn-off/policy/:id`,
  deleteTurnOffPolicyById: `${url}api/v1/employer/turn-off/policy/:id`,
  getSchedule: `${url}api/v1/employer/pay/schedule/all/:id`,
  postPaySchedule: `${url}api/v1/pay/periods/:id`,
  savePaySchedule: `${url}api/v1/pay/periods/save/:id`,
  getPayScheduleById: `${url}api/v1/employer/pay/schedule/:id`,
  getDeductionContributions: `${url}api/v1/employer/deductions-contributions/:id`,
  getfetchCategoryListForDedsAndContr: `${url}api/v1/deductions/contributions/category/list`,
  getDistListType: `${url}api/v1/deductions/contributions/type/list`,
  postDeductionContribution: `${url}api/v1/employer/deductions-contributions/:id`,
  deletePaySchedule: `${url}api/v1/employer/pay/schedule/:id`,
  deleteDynamicPaySchedule: `${url}api/v1/w2employee/w2employee/delete/otherPay/:id`,
  deleteDeductionContribution: `${url}api/v1/employer/deductions-contributions/:id`,
  getDeductionContributionById: `${url}api/v1/deductions/contributions/:id`,
  savePayPeriodMonth: `${url}api/v1/pay/periods/monthly/:id`,
  savePayPeriodTwiceMonth: `${url}api/v1/pay/periods/twice/:id`,
  saveDirectBankAccount: `${url}api/v1/employer/direct-deposit/:id`,
  getDirectBankAccount: `${url}api/v1/employer/direct-deposit/:id`,
  getGarishmentContribution: `${url}api/v1/employer/contribution/:id`,
  getGarishmentDeduction: `${url}api/v1/employer/deduction/:id`,
  deleteGarishmentDeduction: `${url}api/v1/employer/deduction/:id`,
  /*   deleteGarishmentContribution: `${url}api/v1/contribution/:id`, */
  saveDeductionGarnishment: `${url}api/v1/employer/deduction/:id`,
  deleteGarishmentContribution: `${url}api/v1/employees/:id/contributions`,

  getSickTimeOffPolicies: `${url}api/v1/employer/:id/time-off-policies`,
  saveSickTimeOffPolicies: `${url}api/v1/employees/:id/time-off`,
  saveDynamicValue: `${url}api/v1/w2employee/w2employee/otherPay/:id`,
  updateDynamicValue: `${url}api/v1/w2employee/w2employee/update/otherPay`,

  getAllSickTimeOffPolicies: `${url}api/v1/employees/:id/time-off`,
  deleteAllSickTimeOffPolicies: `${url}api/v1/employees/:id/time-off`,
  getAllEditSickLeave: `${url}api/v1/employees/:id/time-off`,
  updateSickTimeOffPolicies: `${url}api/v1/employees/:id/time-off`,

  addBusinessPay: `${url}api/v1/business/pay/:id`,
  getBusinessPay: `${url}api/v1/business/pay/:id`,
  getPayrollSchedule: `${url}api/v1/fetch/pay/schedules/:id`,
  getPayrollFetchTable: `${url}api/v1/fetch/employees/:id`,
  getSecondPayrollFetchTable: `${url}api/v1/fetch/employee/:id`,
  savePayrollSetup: `${url}api/v1/create/paycheck`,
  deleteApprovePaycheck: `${url}api/v1/delete/paycheck/:id`,
  /*  saveGarnishment: `${url}api/v1/employees/:id/garnishments`,
   getGarnishment: `${url}api/v1/employees/:id/garnishments`,
   getEditGarnishment: `${url}api/v1/employees/:id/garnishments`, */
  saveGarnishment: `${url}api/v1/employees/:id/garnishments`,
  getGarnishment: `${url}api/v1/employees/:id/garnishments`,
  getEditGarnishment: `${url}api/v1/employees/:id/garnishments`,
  /*   getCompanyContribution: `${url}api/v1/contribution/list/:id`, */
  getCompanyContribution: `${url}api/v1/employees/:id/contributions`,
  getCompanyContributionDropdown: `${url}api/v1/contributions/dropdown`,
  getCompanyContributionDropdownType: `${url}api/v1/contributions/category/list`,
  /*   saveCompanyContribution: `${url}api/v1/contribution/:id`, */
  saveCompanyContribution: `${url}api/v1/employees/:id/contributions`,
  updateCompanyContribution: `${url}api/v1/employees/:id/contributions`,
  /*  getCompanyContributionById: `${url}api/v1/contribution/:id`, */
  getCompanyContributionById: `${url}api/v1/employees/:id/contributions`,
  getContactorDetail: `${url}api/v1/fetch/contractors/:id`,
  savePayrollContractor: `${url}api/v1/create/paycheck/contractor`,
  getDirectDepositByEmployerId: `${url}api/v1/employer/direct-deposit/details/:id`,
  getKotayPay: `${url}api/v1/process/payroll/kotapay/:id`,
  getTodoList: `${url}api/v1/dashboard/to-do/?`,
  getTaxName: `${url}api/v1/employee/local-tax/names/:id`,
  getTaxCalculation: `${url}api/v1/tax-calculations/:id`,
  getStateTaxName: `${url}api/v1/employee/state-tax/names/:id`,
  getPayrollDeduction: `${url}api/v1/payroll/:id`,
  postPayrollDeduction: `${url}api/v1/payroll/:id`,
  saveBusniessAddress: `${url}api/v1/address/:id`,
  getBusniessAddress: `${url}api/v1/address/:id`,
  deleteBusniessAddress: `${url}api/v1/address/:id`,
  primaryAddress: `${url}api/v1/primary-address/:id`,
  getEmployeStateTax: `${url}api/v1/employer/state-tax/names/:id`,
  uploadKotaPay: `${url}api/v1/employer/kota-pay/upload/:id`,
  uploadForm8655: `${url}api/v1/employer/form/direct-deposit/upload/:id`,
  getForm8655: `${url}api/v1/process/payroll/form-8655/:id`,
  getSignedKotaPayDownload: `${url}api/v1view/signed/kotapay/form/:id`,
  getSignedForm8655Download: `${url}api/v1view/signed/form/:id`,
  postStateTaxNameEmployer: `${url}api/v1/employer/state-tax/names/:id`,
  fetchEmployerFederaltaxNames: `${url}api/v1/employer/federal-tax/names`,
  getLocalTaxName: `${url}api/v1/employer/local-tax/names/:id`,
  getPayStubData: `${url}api/v1/pay-stub/:id`,
  getPayPeriodDropDown: `${url}api/v1/pay-period/dropdown/:id`,
  getPayDate: `${url}api/v1/pay-date/:id`,
  getPayrollPrint: `${url}api/v1/process/payroll/print/:id`,
  getPayrollPrintPayCheck: `${url}api/v1/print/paycheck/:id`,
  postPayrollPrintPayCheck: `${url}api/v1/process/payroll/print/:id`,
  formNYS1: `${url}api/v1/process/payroll/form-nys1/:id`,
  formNYS45: `${url}api/v1/process/payroll/form-nys45/:id`,
  payrollPrintPdf: `${url}api/v1/process/payroll/print`,
  achDownloadFile: `${url}api/v1/ach-file/download/:id`,
  postPaycheck: `${url}api/v1/manual/paycheck/:id`,
  achcalendar: `${url}api/v1/ach/calendar`,
  getEmployeePayrollSummaryExcel: `${url}api/v1/employee/payroll-summary/excel/:id`,
  getEmployeeTaxLiabilityExcel: `${url}api/v1/employer/tax-liability/excel/:id`,
  getEmployeePayrollDetailExcel: `${url}api/v1/employee/payroll-detail/excel/:id`,
  getTaxPaymentExcel: `${url}api/v1/employer/tax-payment/excel/:id`,
  getPayCheckListExcel: `${url}api/v1/employee/paycheck-list/excel/:id`,
  getDeductionContributionExcel: `${url}api/v1/employee/deduction-contribution/excel/:id`,
  getTotalCostReportExcel: `${url}api/v1/employer/total-cost/excel/:id`,
  sickVacationExcel: `${url}api/v1/employer/sick-and-vacation/excel/:id`,
  getRetirementPlanExcel: `${url}api/v1/employer/retirement-plan/excel/:id`,
  getTaxWagesExcel: `${url}api/v1/employer/tax-and-wage-summary/excel/:id`,
  getBillingExcel: `${url}api/v1/admin/billing/excel`,
  saveRolePolicy: `${url}api/v1/employer/policy/update`,
  getContractorPaymentListExcel: `${url}api/v1/contractor/payments-list/excel/:id`,
  getTeamMemberExcel: `${url}api/v1/admin/team-member-directory/excel`,
  downloadBillingPdf: `${url}api/v1/admin/billing/pdf`,
  getTeamMemberDirectoryPdf: `${url}api/v1/admin/team-member-directory/pdf`,
  getEmployeeDedsAndContrPdf: `${url}api/v1/employee/deduction-contribution/pdf/:id`,
  getEmployeePaycheckListPdf: `${url}api/v1/employee/paycheck-list/pdf/:id`,
  getContractrPaySummaryPDF: `${url}api/v1/contractor/pay-summary/report/pdf/:id`,
  getContractorTaxPaymentPdf: `${url}api/v1/contractor/payments-list/pdf/:id`,
  getTotalCostReportPdf: `${url}api/v1/employer/total-cost/pdf/:id`,
  getTaxPaymentPdf: `${url}api/v1/employer/tax-payment/pdf/:id`,
  getEmployerTaxLiabilityPdf: `${url}api/v1/employer/tax-liability/pdf/:id`,
  getEmployerTaxAndWageSummaryPdf: `${url}api/v1/employer/tax-and-wage-summary/pdf/:id`,
  getVacationAndSickLeavePdf: `${url}api/v1/employer/sick-and-vacation/pdf/:id`,
  getRetirementPlansPdf: `${url}api/v1/employer/retirement-plan/pdf/:id`,
  getEmployeePayrollSummaryPdf: `${url}api/v1/employee/payroll-summary/pdf/:id`,
  getEmployeePayrollDetailPdf: `${url}api/v1/employee/payroll-detail/pdf/:id`,
  getContractorPaySummaryExcel: `${url}api/v1/contractor/pay-summary/report/excel/:id`,
  approveTaxPaymentManually: `${url}api/v1/pay/taxes/approve/manual/:id`,
  downloadAchFileTax: `${url}api/v1/ach/tax-file/download/:id`,
  downloadExcelWorkCompensation: `${url}api/v1/employer/workers-compensation/excel/:id`,
  downloadPDFWorkCompensation: `${url}api/v1/employer/workers-compensation/pdf/:id`,
  employerPolicy: `${url}api/v1/employer/policy`,
  getDeductionName: `${url}api/v1/deduction-names/:id`,
  postDeductionName: `${url}api/v1/deduction-names`,
  deleteTax: `${url}api/v1/deleteTax/:taxId`,
  downloadTexData: `${url}api/v1/ach/tax-file/download/:id`,
  deleteAllEmployee: `${url}api/v1/employee/deleteAll`,
  employerCount: `${url}api/v1/employer/count`,
  totalGrossPay: `${url}api/v1/total/grossPayment`,
  appRovePayCheckCount:  `${url}api/v1/approvePaycheck/count`,
  pendingPayCheckCount: `${url}api/v1/pendingPaycheck/count`,
  contractorNEmpPayment: `${url}api/v1/totalEmployeePayments`,
  getPayrollDetailChart: `${url}api/v1/getTotalPayrollDetails`,
  getTaxCollectionChart: `${url}api/v1/getTotalTaxesCollection`,
  getEmployeePaycheckDetail: `${url}api/v1/getEmployeePaycheckDetails`,
  sickAndVacationLeave: `${url}api/v1/employeeSickAndVacation`,
  employeeTaxDetail: `${url}api/v1/employeeTaxDetails`,
  getEmployeeSalary: `${url}api/v1/getEmployeeSalary`,
  getEmployeeTotalTax: `${url}api/v1/getEmployeeTotalTax`,
  workerClassCompansiation: `${url}api/v1/employer/workerDisabilityCompensation/report/:id`,
  getEmployerPaycheckDetails: `${url}api/v1/getEmployerPaycheckDetails`,
  employerTaxDetails: `${url}api/v1/employerTaxDetails`,
  getSubscription: `${url}api/v1/subscription`,
  updateSubscriptionPermission: `${url}api/v1/subscription/update_subscription_permissions`,
  dashEmployeeCount: `${url}api/v1/employee/count/:id`,
  employerTaxReport: `${url}api/v1/employer/tax/report/:id`,
  delePaychecks: `${url}api/v1/delete/all/paycheck`,
  createPlan: `${url}api/v1/subscription`,
  subscriptionPlan: `${url}api/v1/subscription/api/v1/subscription`,
  updateStatus: `${url}api/v1/subscription/status`,
  linkEmployer: `${url}api/v1/subscription/link_employer`,
  logout: `${url}api/v1/logout`,
};
