<div class="h100 wd" style="overflow: hidden;">
    <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-6 pr-5 center bgColor h100">
            <img width="80%" class="imgClass center margin-img" src="../../assets/images/login_image.svg" alt="">
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6 inherit">
            <div class="row mt23">
                <div class="col-md-12 my-auto">
                    <div>
                        <img width="63%" style="margin-left: 18%;" src="../../assets/images/logo.png" alt="">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="col-md-8 dis-flex">
                        <h3 class="loginData">Reset Password </h3>
                    </div>
                </div>
                <br>
                <div *ngIf="emailVerifyView">
                    <div class="col-md-8 pb-3">
                        <div class="form-group w150">
                            <label for="username">Email Address</label>
                            <input type="email" class="form-control" [(ngModel)]="reqEmail" pInputText>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <button class="btn btn-md" style="width: 67%;" class="submitButton" type="button"
                                (click)="verifyEmail()">Request</button>
                        </div>
                    </div>
                </div>

                <div *ngIf="!emailVerifyView">
                    <div class="col-md-8" style="margin-left: 18%;">
                    </div>
                    <form #form="ngForm">
                    <div class="col-md-8 margin-10" style="margin-left: 18%;">
                        <label for="password">Password</label>
                        <div class="form-group p-inputgroup">
                            <span class="p-inputgroup-addon bg-white" style="max-height: 30px !important">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                            </span>
                            <input [ngClass]="{'error': oneUpperCase}" (keyup)="pswdChange(password.value)" #password type="{{!showText ? 'password' : 'text'}}" class="form-control el-width" [(ngModel)]="changePwdObj.password" pInputText name="password"
                            [maxlength]="20" [minlength]="8" required>
                            <i (click)="showText = !showText" class="show-hide-icon" [ngClass]="showText ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i>
                            <div class="col-12 align-class">
                                <span class="error-class" *ngIf="form.form.controls.password?.errors && (form.form.controls.password?.errors?.minlength || form.form.controls.password?.errors?.maxlength)">
                                    Password contains at least 8 characters and at most 20 characters.
                                </span>
                                <span class="error-class" *ngIf="oneUpperCase">{{oneUpperCase}}</span>
                                <span class="error-class" *ngIf="whiteSpace">{{whiteSpace}}</span>
                                <span class="error-class special" *ngIf="specialChar">{{specialChar}}</span>
                                <span class="error-class" *ngIf="lowerCase">{{lowerCase}}</span>
                                <span class="error-class" *ngIf="oneDigit">{{oneDigit}}</span> 
                            </div>                           
                        </div>
                    </div>
                    <div class="col-md-8 margin-10" style="margin-left: 18%; margin-top: 25px">
                        <label for="password">Confirm Password</label>
                        <div class="form-group p-inputgroup">
                            <span class="p-inputgroup-addon bg-white" style="max-height: 30px;">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                            </span>
                            <input type="{{!showTextCnf ? 'password' : 'text'}}" class="form-control el-width" [(ngModel)]="changePwdObj.confirmPwd"
                                pInputText name="confirmPassword" [pattern]="password.value" required>
                            <i (click)="showTextCnf = !showTextCnf" class="show-hide-icon confirm-pswd" [ngClass]="showTextCnf ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i>
                            <div class="col-12 align-class">
                                <span class="error-class" *ngIf="changePwdObj.password !== changePwdObj.confirmPwd && form.form.controls.confirmPassword.dirty">
                                    Confirmed Password should be same as Password.</span>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-8 margin-10 pd-25" style="margin-left: 18%;">
                        <div class="form-group">
                            <button [disabled]="!form.valid" style="width: 100%;" class="submitButton" type="button" (click)="resetPassword()">
                                Submit</button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>