import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { LoginService } from './login.service';
import { LoginReactiveService } from './login-service';
import { RandomServiceService } from '../service/random-service.service';
import { ClientService } from '../client/client.service';
import { CustomerService } from '../client/employer-setup/customerService';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginUser = {
    emailId: '',
    password: ''
  };
  value: any;
  public token: string;
  userId: any;
  firstName: any;
  resetPasswordPage: boolean;
  errEmail: boolean;
  roleValue: any;
  employerId: any;
  emlopyees: any;
  businessName: string;
  employeeId: any;
  userType: any;
  contractorId: any;
  roleType: any;
  showText: boolean;
  showCapsLockText: boolean;
  @HostListener('window:keyup', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const capsOn = event.getModifierState && event.getModifierState('CapsLock');
    if (event.key === 'CapsLock') {
      this.showCapsLockText = !this.showCapsLockText;
      localStorage.setItem('capsLockValue', JSON.stringify(this.showCapsLockText))
    }
  }

  constructor(
    readonly loginService: LoginService,
    readonly toast: ToastrService,
    readonly router: Router,
    readonly location: Location,
    public loginReactiveService: LoginReactiveService,
    readonly customer: CustomerService,
    // tslint:disable-next-line: variable-name
    readonly _clitent: ClientService,
    readonly someserv: RandomServiceService
  ) { }

  ngOnInit(): void {
    this.resetPasswordPage = false;
    this.loginReactiveService.login_form = null;
    this.loginReactiveService.loginForm(this.loginReactiveService.login_form);
    this.showCapsLockText = JSON.parse(localStorage.getItem('capsLockValue'));
  }

  submitted = false;
  loginuser(): void {
    if (this.submitted) return;
    localStorage.clear();
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (EMAIL_REGEXP.test(this.loginUser.emailId)) {
      this.submitted = true;
      this.loginService.loginDetails(this.loginUser).subscribe((res) => {
        this.loginUser = res;
        this.value = res.data.authToken;
        this.roleValue = res.data.role.permissionMap;
        this.userId = res.data.emailAddress;
        this.firstName = res.data.firstName;
        this.employerId = res.data.employerId;
        this.employeeId = res.data.employeeId;
        this.userType = res.data.userType;
        this.contractorId = res.data.contractorId;
        this.roleType = res.data.role.roleName;
        if (res.data.employerIds.length === 1) {
          this.employerId = res.data.employerIds[0];
        }
        this.setLocalStorage2(res);
      }, (err) => {
        this.submitted = false;
        if (err.error.length > 0) {
          this.toast.error(err.error[0].message);
        } else {
          this.toast.error(err.error.message);
        }
        if (!err.success && err.data && !err.data.loginAttempt) {
          this.toast.error(err.message);
          this.router.navigate(['resetPassword'])
        }
      }, () => { this.submitted = false; });
    }
    else {
      this.toast.error('Email address is not valid');
    }
  }

  setLocalStorage2(res): void {
    if (res) {
      this.setLocalStorage();
      this.token = localStorage.getItem('token');
      if (this.employerId) {
        localStorage.setItem('employerSelectId', this.employerId);
        // this.getAllList();
      }
      if (this.contractorId) {
        localStorage.setItem('selectContractorId', this.contractorId);
      }
      if (this.employeeId) {
        localStorage.setItem('employerSelectId', this.employerId);
        localStorage.setItem('selectEmployeeId', this.employeeId);
        // this.getAllList();
      }
      this.defaultNavigation(this.userType);
    }
    else if (res.success === false) {
      if (res.error[0].message) {
        this.toast.error(res.error[0].message);
      } else {
        this.toast.error(res.error.message);
      }
    }
  }

  setLocalStorage(): void {
    localStorage.setItem('token', (this.value));
    localStorage.setItem('employeLoginId', this.employerId);
    localStorage.setItem('userId', (this.userId));
    localStorage.setItem('firstName', (this.firstName));
    localStorage.setItem('userType', (this.userType));
    localStorage.setItem('roleTypeName', (this.roleType));
    localStorage.setItem('roleValueLogin', JSON.stringify(this.roleValue));
    localStorage.setItem('roleValueLogin', JSON.stringify(this.roleValue));
  }

  defaultNavigation(type): void {
    switch (type) {
      case 'W2_EMPLOYEE':
        this.router.navigate(['/dashboard']);
        this.location.replaceState('/dashboard');
        break;
      case 'CONTRACTOR':
        this.router.navigate(['/allClient/teamMembers/allteamMember']);
        this.location.replaceState('/allClient/teamMembers/allteamMember');
        break;
      default:
        this.router.navigate(['/dashboard']);
        this.location.replaceState('/dashboard');
        break;
    }
  }

  getAllList(): any {
    const reqBody = {
      pageNo: 1,
      pageSize: 1000,
    };
    this.customer.getDataList().subscribe((res: any) => {
      this.emlopyees = res.data.content;
      for (const r of this.emlopyees) {
        if (r.id === this.employerId) {
          localStorage.setItem('businessName', r.businessName);
          this.businessName = localStorage.getItem('businessName');

          this.someserv.editMsg(this.businessName);
        }
      }
    });
  }

  validationCheck(e: any): void {
    const email = e.target.value;
    const valid = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(email);
    if (!valid) {
      this.errEmail = true;
    }
  }

  resetPassword(): void {
    this.router.navigate(['/resetPassword']);
  }

  capsLockEvent(event) {
    this.showCapsLockText = event;
  }

  capsEvent(event: KeyboardEvent): void {
    this.showCapsLockText = event.getModifierState && event.getModifierState('CapsLock');
  }

  ngOnDestroy(): void {
    localStorage.removeItem('capsLockValue')
  }
}
