import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeNameService {
  readonly msgsource = new BehaviorSubject<string>('Employee Name');
  telecast = this.msgsource.asObservable();
  constructor() { }
  editMsg(newmsg): void {
    setTimeout(() =>  this.msgsource.next(newmsg), 0);
  }
}
