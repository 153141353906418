<div class="h100 wd" style="overflow: hidden;">
    <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-6 bgColor h100" style="border-radius: 0;">
            <img class="imgClass mrt-50" style="cursor: pointer; width: 80%; margin: 12% 15%;" (click)="changeUrl();"
                src="../../assets/images/login_image.svg" alt="">
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6 inherit">
            <div class="row mt23 mrt-50">
                <div class="col-md-12 my-auto">
                    <div>
                        <img width="50%" style="margin-left: 25%;" src="../../assets/images/logo.png" alt="">
                    </div>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <div class="col-md-8 d-flex justify-content-center"> 
                        <h3 class="loginData" style="font-size: 30px !important;">Reset Password </h3>
                    </div>
                </div>
                <br>
                <div *ngIf="emailVerifyView">
                    <div class="col-md-8 pb-3 mrg-8" style="margin-left: 16%;">
                        <label for="username">Email Address</label>
                        <div class="form-group w150 p-inputgroup">
                            <span class="p-inputgroup-addon bg-white">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            </span>
                            <input type="email" class="form-control" [(ngModel)]="reqEmail" pInputText>
                        </div>
                    </div>
                    <div class="col-md-12 mrg-none" style="margin-left: 16%;">
                        <div class="form-group buttn-rst">
                            <button style="width: 67%;" class="btn btn-md" class="submitButton" type="button"
                                (click)="verifyEmail()">Request</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>