import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  emailVerifyView: boolean;
  reqEmail: any;
  changePwdObj: any = {};
  userData: any = {};

  constructor(
    readonly loginService: LoginService,
    readonly toast: ToastrService,
    readonly router: Router
  ) { }

  ngOnInit(): void {
    this.emailVerifyView = true;
  }

  changeUrl(): void {
    this.router.navigate(['/']);
  }

  verifyEmail(): void {
    const obj = {
      emailAddress: this.reqEmail
    };
    this.loginService.sendVerifyEmail(obj).subscribe((data: any) => {
      if (data && data.status === 200) {
        this.emailVerifyView = false;
        this.userData = data.data;
        this.toast.success(data.message);
        this.router.navigate(['/login']);
      }
      else {
        this.toast.warning(data.message);
      }
    }, (err) => {
      this.toast.error(err.error.message);
    });
  }
}
