import { NgModule } from "@angular/core";
import { SvgIconDirective } from "./svg-icon.directive";
import { SearchNamePipe } from "./search-name.pipe";
import { TwoDigitDecimaNumberDirectiveDirective } from "./service/two-digit-decima-number-directive.directive";

@NgModule({
    declarations: [
        SvgIconDirective, 
        TwoDigitDecimaNumberDirectiveDirective,
        SearchNamePipe],
    exports: [SvgIconDirective, SearchNamePipe, TwoDigitDecimaNumberDirectiveDirective],
    providers: [TwoDigitDecimaNumberDirectiveDirective]
})
export class SharedModule {}