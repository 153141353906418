import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RandomServiceService {
  readonly msgsource = new BehaviorSubject<string>('Slick Payroll');
  telecast = this.msgsource.asObservable();
  constructor() { }
  editMsg(newmsg): void {
    setTimeout(() =>  this.msgsource.next(newmsg), 0);
  }
  broadcastMessage(newmsg): void {
    setTimeout(() => this.msgsource.next(newmsg))
  }
}
