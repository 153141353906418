import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "./app.service";
import { EmployeeNameService } from "./employee-name.service";
import { RandomServiceService } from "./random-service.service";
import { UserService } from "../user-management/user.service";
import { ContractorNameService } from "./contractor-name.service";
import { EmployeeService } from "./employee.service";

@Injectable({
  'providedIn': 'root'
})
export class AutoLogOffService {

  //log off details
  isLogin = false;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    readonly appService: AppService,
    readonly employeeService: EmployeeNameService,
    readonly someserv: RandomServiceService,
    readonly userService: UserService,
    readonly contractorService: ContractorNameService,
    readonly empIdSer: EmployeeService
  ) {
    if (this.isUserLoggedIn()) {
      this.isLogin = true;
    }
    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
  }

  /**
   * last action
   */
  getLastAction() {
    return localStorage.getItem('lastAction');
  }

  /**
   * set last action
   * @param value
   */
  lastAction(value) {
    localStorage.setItem('lastAction', JSON.stringify(value))
  }

  /**
   * start event listener
   */
  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  /**
   * time interval
   */
  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    })
  }

  removeLocalStorage(): void {
    localStorage.removeItem('employeeNameFixedBasic');
    localStorage.removeItem('employerSelectId');
    localStorage.removeItem('lastName');
    localStorage.removeItem('catalog');
    localStorage.removeItem('employeeNameFixedEmployment');
    localStorage.removeItem('userId');
    localStorage.removeItem('partyId');
    localStorage.removeItem('adminSecurity');
    localStorage.removeItem('firstName');
    localStorage.removeItem('resetToken');
    localStorage.removeItem('firstNameHead');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('businessName');
    localStorage.removeItem('selectEmployeeId');
    localStorage.removeItem('sideBarShowHide');
    localStorage.removeItem('editFlag');
    localStorage.removeItem('editSecondFlag');
    localStorage.removeItem('taxDetailsData');
    localStorage.removeItem('downloadMultiplePayroll');
    localStorage.removeItem('selectContractorId');
    localStorage.removeItem('roleValueLogin');
    localStorage.removeItem('employeLoginId');
    localStorage.removeItem('taxesAchFileValue');
    localStorage.removeItem('userType');
    localStorage.removeItem('setIdSave');
    localStorage.removeItem('taxesAchFileValue');
  }

  /**
   * reset timer
   */
  reset() {
    this.lastAction(Date.now());
  }

  logout(): void {
    this.userService.logout().subscribe((data: any) => {
    });
    this.removeLocalStorage();
    this.someserv.broadcastMessage('Slick Payroll');
    this.empIdSer.editId('0');
    this.employeeService.editMsg('Employee Name');
    this.contractorService.editMsg('Contractor Name');
    localStorage.clear();
    this.appService.toggleSideBar(false);
    this.router.navigate(['/login']);
  }

  /**
   * check timer
   */
  check() {
    const now = Date.now();
    const timeLeft = parseInt(this.getLastAction()) + (10) * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    // this.isLoggedIn.subscribe(event => this.isLogin = event);
    this.ngZone.run(() => {
      if (isTimeout && this.isLogin) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('lastAction');
        localStorage.removeItem('token');
        setTimeout(() => {
          console.log("Your Session Expired due to longer Inactivity, Login Again To Continue");
        }, 10000);
        this.logout();
      }
    });
  }

  /**
   *check if a user is logged in
   */
  isUserLoggedIn(): string {
    return localStorage.getItem('token')
  }
}