import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchName'
})
export class SearchNamePipe implements PipeTransform {

  transform(value: any, args: any, name: string): any {
    if(!value)return null;
    if(!args)return value;

    args = args?.toLowerCase();

    return value.filter((item) => {
        return item[name]?.toLowerCase()?.includes(args);
    });

  }

}
