import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SvgIcons } from './app-svgIcon';

@Directive({
  selector: '[appSvgIcon]'
})
export class SvgIconDirective implements AfterViewInit {

  constructor(public targetElement: ElementRef) {}
  @Input('appSvgIcon') icon: string;

  ngAfterViewInit(): void {
    this.addSvgIcon();
  }

  path(icon): any {
    return SvgIcons[icon];
  }

  addSvgIcon(): void {
    this.targetElement.nativeElement.innerHTML = this.path(this.icon) ? this.path(this.icon) : '';
  }

}
